<template>
  <div>
    <v-avatar v-if="!user?.user_name" :size="width">
      <v-icon icon="mdi-account-circle" :size="width"></v-icon>
    </v-avatar>

    <v-img v-else :width="width" :height="height" class="picture"
      :src="`https://ecom-profile-picture.s3.eu-central-1.amazonaws.com/${user.user_id}.png?cache=${userStore.pictureCache}`">

      <template v-slot:error>
        <v-avatar v-if="getInitials" color="#83ECC2" :size="width" style="border: 1px solid white">
          <span :style="{ 'font-size': fontSize }">{{ getInitials(user.user_name) }}</span>
        </v-avatar>
      </template>
    </v-img>
  </div>
</template>

<script setup>
import { useUserStore } from '~/stores/user';

const userStore = useUserStore()

const props = defineProps({
  user: Object,
  width: {
    type: Number,
    default: 40
  },
  height: {
    type: Number,
    default: 40
  }
})

const fontSize = computed(() => {
  return props.width < 40 ? 'small' : props.width < 60 ? 'medium' : 'large'
})

</script>

<style lang="scss" scoped>
.picture {
  border-radius: 100px;
}
</style>